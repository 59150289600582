@import '../../../../styles/index.scss';

.container {
  margin: 0 auto;
  @include mainMarginTop;
  width: 100%;
  max-width: 1300px;
  height: 315px;
  position: relative;

  @media (min-width: 786px) and (max-width: 1170px) {
    height: 225px;
  }

  @media (min-width: 586px) and (max-width: 785px) {
    height: 155px;
  }

  @media (min-width: 426px) and (max-width: 585px) {
    height: 115px;
  }

  @media (min-width: 280px) and (max-width: 425px) {
    height: 85px;
  }
}

.additionalContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.image {
  padding: 2px;
  width: 14%;
  max-width: 385px;
}
