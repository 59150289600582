@import '../../../../styles/index.scss';

.container {
  @include secondaryMarginTop;
}

.text {
  @include textProcedurePage;
  text-align: justify;
}
