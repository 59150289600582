@import '../../../../styles/index.scss';

.container {
  @include secondaryMarginTop;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  div:last-child {
    margin-bottom: 0;
  }
}

.contantWrap {
  margin-bottom: 30px;
  text-align: justify;
  position: relative;
  margin-left: 50px;

  @media (min-width: 280px) and (max-width: 500px) {
    margin-left: 30px;
  }
}

.line {
  position: absolute;
  width: 80px;
  height: 4px;
  background-color: $darkPurple;
  top: 50%;
  transform: translate(-50%);
  left: -70px;

  @media (min-width: 501px) and (max-width: 900px) {
    width: 50px;
    left: -50px;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    width: 30px;
    height: 2px;
    left: -30px;
  }
}

.title {
  @include titleProcedurePage;
}

.subtitle {
  @include textProcedurePage;
  font-weight: $medium;
}

.text {
  @include textProcedurePage;
  margin-left: 5px;
}
