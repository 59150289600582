@import '../../../../styles/index.scss';

.container {
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 280px) and (max-width: 800px) {
    width: 100%;
  }
}

.right {
  align-self: flex-end;

  @media (min-width: 280px) and (max-width: 800px) {
    margin-top: 40px;
  }

  @media (min-width: 280px) and (max-width: 421px) {
    align-self: flex-start;
  }
}

.title {
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 26px;
  color: white;
  background: $darkPurple;
  padding: 15px 20px;
  border-radius: 5px 5px 0 0;

  @media (min-width: 701px) and (max-width: 1100px) {
    font-size: 23px;
  }

  @media (min-width: 280px) and (max-width: 700px) {
    font-size: 18px;
  }

  @media (min-width: 280px) and (max-width: 421px) {
    padding: 10px 15px;
    font-size: 16px;
  }
}

.content {
  width: 100%;
  height: 340px;
  background-color: $lightPurple;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 801px) and (max-width: 1100px) {
    height: 280px;
  }

  @media (min-width: 531px) and (max-width: 800px) {
    height: 300px;
  }

  @media (min-width: 280px) and (max-width: 530px) {
    height: 200px;
  }
}

.gradient {
  height: 100%;
  width: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(235, 230, 237, 0.07) 0%,
    rgba(235, 230, 237, 0.28) 28.12%,
    rgba(235, 230, 237, 0.43) 49.48%,
    rgba(235, 230, 237, 0.56) 69.79%,
    rgba(235, 230, 237, 0.91) 85.94%,
    #ebe6ed 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.button {
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 20px;
  color: white;
  background: $darkPurple;
  border: 1px solid $darkPurple;
  padding: 20px 35px;
  margin: 25px;
  border-radius: 5px;

  @media (min-width: 701px) and (max-width: 1100px) {
    font-size: 18px;
  }

  @media (min-width: 280px) and (max-width: 700px) {
    font-size: 16px;
    padding: 15px 25px;
  }

  @media (min-width: 280px) and (max-width: 421px) {
    font-size: 14px;
    padding: 10px 20px;
    margin: 15px;
  }

  &:hover {
    color: $darkPurple;
    background: transparent;
  }
}
