@import './responsive';

// colors
$darkPurple: #682c8f;
$lightPurple: #ebe6ed;
$grayBackground: #f4f3f3;

// fonts
$fontMontserrat: 'Montserrat', sans-serif;

//font-weight
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;

@mixin layout {
  width: 86%;
  max-width: 1300px;
  margin: 0 auto;

  @media (min-width: 280px) and (max-width: 740px) {
    width: 90%;
  }
}

@mixin heading {
  font-family: $fontMontserrat;
  font-size: 55px;
  font-weight: $regular;

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 40px;
  }

  @media (min-width: 441px) and (max-width: 899px) {
    font-size: 35px;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 20px;
  }
}

@mixin mainMarginTop {
  margin-top: 70px;

  @media (min-width: 421px) and (max-width: 900px) {
    margin-top: 50px;
  }

  @include mobile {
    margin-top: 40px;
  }
}

@mixin secondaryMarginTop {
  margin-top: 50px;

  @media (min-width: 421px) and (max-width: 900px) {
    margin-top: 30px;
  }

  @include mobile {
    margin-top: 40px;
  }
}

@mixin secondaryMarginBottom {
  margin-bottom: 50px;

  @media (min-width: 421px) and (max-width: 900px) {
    margin-bottom: 30px;
  }

  @include mobile {
    margin-bottom: 40px;
  }
}

@mixin textProcedurePage {
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 20px;
  line-height: 170%;

  @media (min-width: 501px) and (max-width: 900px) {
    font-size: 14px;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    font-size: 12px;
  }
}

@mixin titleProcedurePage {
  display: block;
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 35px;
  line-height: 170%;
  margin-bottom: 15px;

  @media (min-width: 501px) and (max-width: 900px) {
    font-size: 30px;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    font-size: 16px;
  }
}
