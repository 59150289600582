@import '../../../styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include heading;
  @include mainMarginTop;
}

.content {
  margin: 0 auto;
  @include secondaryMarginTop;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 280px) and (max-width: 900px) {
    width: 80%;
  }
}

.imgWrap {
  width: 100%;
  @include secondaryMarginBottom;
}
