@import '../../../../styles/index.scss';

.container {
  @include secondaryMarginTop;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title {
  @include titleProcedurePage;
}

.text {
  @include textProcedurePage;
  text-align: justify;
}
