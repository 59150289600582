@import '../../../../styles/index.scss';

.container {
  @include layout;
  @include mainMarginTop;
  display: flex;
  flex-direction: column;
}

.title {
  @include heading;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include mainMarginTop;

  @media (min-width: 280px) and (max-width: 800px) {
    flex-direction: column;
  }
}
