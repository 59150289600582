@import '../../../../styles/index.scss';

.container {
  margin: 0 auto;
  @include secondaryMarginTop;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  height: 400px;
  position: relative;

  @media (min-width: 491px) and (max-width: 899px) {
    height: 300px;
  }

  @media (min-width: 280px) and (max-width: 490px) {
    height: 200px;
  }
}

.containerBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  background-color: $lightPurple;
  background-position: start;
  background-repeat: no-repeat;
  background-size: cover;
}

.titleWrap {
  width: 50%;
  border: 2px solid $darkPurple;
  background-color: $darkPurple;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    color: $darkPurple;
    background-color: rgba($lightPurple, 0.5);
  }
}

.title {
  margin: 30px 20px;
  font-family: $fontMontserrat;
  font-size: 30px;
  font-weight: $regular;
  text-align: center;

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 20px;
  }

  @media (min-width: 441px) and (max-width: 899px) {
    font-size: 15px;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 14px;
  }
}
