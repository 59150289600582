@import '../../../../styles/index.scss';

@mixin height {
  height: 350px;

  @media (min-width: 300px) and (max-width: 1220px) {
    height: 300px;
  }
}

.container {
  margin: 0 auto;
  @include height;
  @include mainMarginTop;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1300px;
  background-color: $lightPurple;
}

.backgroundImage {
  background-color: $darkPurple;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: 701px) and (max-width: 1220px) {
    width: 100px;
  }

  @media (min-width: 280px) and (max-width: 700px) {
    width: 70px;
  }
}

.contentImgWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.contentWrap {
  width: 70%;
  height: 100%;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 701px) and (max-width: 899px) {
    width: 70%;
  }

  @media (min-width: 441px) and (max-width: 700px) {
    width: 100%;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    padding: 0 15px;
    width: 100%;
  }
}

.title {
  width: 80%;
  margin: 0 autp;
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 30px;
  color: $darkPurple;

  @media (min-width: 441px) and (max-width: 1220px) {
    font-size: 20px;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 18px;
  }
}

.subtitle {
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 20px;
  margin-top: 30px;

  @media (min-width: 441px) and (max-width: 1220px) {
    font-size: 16px;
    margin-top: 20px;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 14px;
    margin-top: 15px;
  }
}

.imgWrap {
  width: 30%;
  @include height;
  background-color: $lightPurple;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (min-width: 441px) and (max-width: 899px) {
    width: 30%;
  }

  @media (min-width: 280px) and (max-width: 700px) {
    display: none;
  }
}
