@import '../../../../styles//index.scss';

.container {
  @include layout;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media (min-width: 280px) and (max-width: 670px) {
    flex-direction: column-reverse;
  }
}

.contentBlock {
  margin-top: 180px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 900px) and (max-width: 1200px) {
    margin-top: 150px;
  }

  @media (min-width: 671px) and (max-width: 899px) {
    margin-top: 70px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    margin-top: 20px;
    width: 100%;
    align-items: center;
  }
}

.title {
  font-family: $fontMontserrat;
  font-size: 35px;
  font-weight: $regular;
  text-align: start;

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 30px;
  }

  @media (min-width: 441px) and (max-width: 899px) {
    font-size: 25px;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 20px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    text-align: center;
  }
}

.description {
  font-family: $fontMontserrat;
  font-size: 20px;
  font-weight: $light;
  margin-top: 25px;
  text-align: start;

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 18px;
  }

  @media (min-width: 441px) and (max-width: 899px) {
    font-size: 16px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    text-align: center;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 14px;
    margin-top: 20px;
  }
}

.introduce {
  display: block;
  margin-top: 15px;

  @media (min-width: 280px) and (max-width: 440px) {
    margin-top: 10px;
  }
}

.contentButton {
  padding: 15px 25px;
  font-family: $fontMontserrat;
  font-size: 18px;
  font-weight: $regular;
  color: white;
  background-color: $darkPurple;
  border: 1px solid $darkPurple;
  margin-top: 25px;
  cursor: pointer;
  border-radius: 5px;

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 16px;
  }

  @media (min-width: 441px) and (max-width: 899px) {
    font-size: 14px;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    font-size: 12px;
  }

  &:hover {
    color: $darkPurple;
    background-color: transparent;
  }
}

.imgBlock {
  position: relative;
  width: 50%;
  height: 645px;

  @media (min-width: 900px) and (max-width: 1200px) {
    height: 503px;
  }

  @media (min-width: 671px) and (max-width: 899px) {
    height: 403px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    width: 100%;
    height: 355px;
  }
}

.backgroundImg {
  width: 550px;
  height: 550px;
  position: absolute;
  background-color: $lightPurple;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  top: 70px;
  right: -30px;

  @media (min-width: 900px) and (max-width: 1200px) {
    width: 410px;
    height: 410px;
  }

  @media (min-width: 671px) and (max-width: 899px) {
    width: 290px;
    height: 290px;
    right: -10px;
    top: 76px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    width: 280px;
    height: 280px;
    top: 73px;
    left: 50%;
    transform: translate(-50%);
  }
}

.mainImg {
  width: 640px;
  height: 665px;
  transform: matrix(-1, -0.09, -0.09, 1, 0, 0);

  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 50%;
  top: -48px;
  right: -80px;

  @media (min-width: 900px) and (max-width: 1200px) {
    width: 500px;
    height: 503px;
    top: -26px;
    right: -78px;
  }

  @media (min-width: 671px) and (max-width: 899px) {
    width: 380px;
    height: 383px;
    right: -60px;
    top: -20px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    width: 320px;
    height: 353px;

    left: 50%;
    transform: translate(-50%) matrix(-1, -0.09, -0.09, 1, 0, 0);
    top: 0;
  }
}
