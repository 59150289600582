@import '../../../styles/index.scss';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include heading;
  @include mainMarginTop;
}
