@import '../../../../styles/index.scss';

.button {
  cursor: pointer;
  border: 1px solid $darkPurple;
  color: $darkPurple;
  @include secondaryMarginTop;
  padding: 25px 35px;
  font-family: $fontMontserrat;
  font-weight: $regular;
  font-size: 20px;
  border-radius: 5px;
  width: auto;

  &:hover {
    color: white;
    background-color: $darkPurple;
  }

  @media (min-width: 501px) and (max-width: 900px) {
    padding: 15px 25px;
    font-size: 16px;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    padding: 15px 20px;
    font-size: 14px;
  }
}
