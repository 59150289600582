@import '../../../styles/index.scss';

.container {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @include layout;

  @media (min-width: 280px) and (max-width: 590px) {
    flex-direction: column;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    height: 150px;
    margin-bottom: 20px;
  }
}

.logoWrap {
  @media (min-width: 280px) and (max-width: 740px) {
    width: 100px;
  }
}

.content {
  display: flex;
  justify-content: space-between;

  a {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: $fontMontserrat;
    font-weight: $regular;
    color: black;

    @media (min-width: 740px) and (max-width: 1024px) {
      font-size: 16px;
    }

    @media (min-width: 280px) and (max-width: 739px) {
      font-size: 14px;
    }
  }

  @media (min-width: 440px) and (max-width: 590px) {
    width: 100%;
    justify-content: space-between;
  }

  @media (min-width: 280px) and (max-width: 440px) {
    flex-direction: column-reverse;
  }
}

.address {
  padding-right: 35px;
  border-right: 3px solid $darkPurple;

  @media (min-width: 280px) and (max-width: 1024px) {
    padding-right: 25px;
  }

  @media (min-width: 280px) and (max-width: 590px) {
    border: none;
    width: auto;
    padding: 0;
  }
}

.phone {
  padding-left: 35px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: $fontMontserrat;
  font-weight: $regular;
  color: black;

  @media (min-width: 740px) and (max-width: 1024px) {
    font-size: 16px;
  }

  @media (min-width: 280px) and (max-width: 739px) {
    font-size: 14px;
  }

  @media (min-width: 280px) and (max-width: 1024px) {
    padding-left: 25px;
  }

  @media (min-width: 280px) and (max-width: 590px) {
    border: none;
    width: auto;
    padding: 0;
  }
}

.line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: $lightPurple;
  top: 140px;
  left: 0;
  right: 0;

  @media (min-width: 280px) and (max-width: 440px) {
    top: 180px;
  }
}
