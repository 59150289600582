@import '../../../../../styles/index.scss';

.container {
  background: white;
  width: 32%;
  height: 375px;
  display: flex;
  flex-direction: column;
  @include secondaryMarginBottom;

  @media (min-width: 1006px) {
    margin-right: 2%;
  }
  @media (min-width: 656px) and (max-width: 1005px) {
    width: 47.5%;
  }

  @media (min-width: 280px) and (max-width: 655px) {
    width: 80%;
    margin: 0 auto;
  }

  @media (min-width: 280px) and (max-width: 480px) {
    height: 280px;
  }
}

.imgWrap {
  width: 100%;
  height: 225px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 280px) and (max-width: 480px) {
    height: 170px;
  }
}

.content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.title {
  font-family: $fontMontserrat;
  font-size: 24px;
  font-weight: $medium;
  text-align: start;

  @media (min-width: 481px) and (max-width: 1220px) {
    font-size: 20px;
  }

  @media (min-width: 280px) and (max-width: 480px) {
    font-size: 16px;
  }
}

.price {
  font-family: $fontMontserrat;
  font-size: 18px;
  font-weight: $regular;
  margin-top: 10px;

  @media (min-width: 280px) and (max-width: 1220px) {
    font-size: 16px;
  }

  @media (min-width: 280px) and (max-width: 480px) {
    font-size: 12px;
    margin-top: 5px;
  }
}

.button {
  font-family: $fontMontserrat;
  font-size: 14px;
  font-weight: $regular;
  padding: 8px 15px;
  background: $darkPurple;
  border: 1px solid $darkPurple;
  color: white;
  margin-top: 25px;
  border-radius: 3px;

  @media (min-width: 280px) and (max-width: 480px) {
    font-size: 10px;
    margin-top: 15px;
  }

  &:hover {
    background: none;
    color: $darkPurple;
  }
}
