@import '../../../../styles/index.scss';

.container {
  @include layout;
  @include mainMarginTop;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: 1006px) {
    div:nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: 656px) and (max-width: 1005px) {
    flex-direction: row;

    div:nth-child(2n + 1) {
      margin-right: 5%;
    }
  }
}

.buttonContainer {
  height: 76px;

  @media (min-width: 280px) and (max-width: 900px) {
    height: 51px;
  }

  a,
  button {
    margin: 0;
  }
}
