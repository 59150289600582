@import '../../../styles/index.scss';

.commonContainer {
  background-color: $darkPurple;
  width: 100%;
  padding: 50px 0;
  @include mainMarginTop;
}

.container {
  @include layout;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 280px) and (max-width: 540px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  p,
  a {
    color: white;
    font-size: 20px;
    font-family: $fontMontserrat;
    font-weight: $light;

    @media (min-width: 671px) and (max-width: 900px) {
      font-size: 16px;
    }

    @media (min-width: 280px) and (max-width: 670px) {
      font-size: 14px;
    }
  }
}

.contactInfoWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 280px) and (max-width: 540px) {
    align-items: center;
    margin-top: 25px;
  }
}

.address {
  text-align: start;

  @media (min-width: 280px) and (max-width: 540px) {
    text-align: center;
  }
}
.phoneNumber {
  margin-top: 30px;
  color: white;
  font-size: 20px;
  font-family: $fontMontserrat;
  font-weight: $light;

  @media (min-width: 671px) and (max-width: 900px) {
    font-size: 16px;
  }

  @media (min-width: 280px) and (max-width: 670px) {
    font-size: 14px;
    margin-top: 20px;
  }
}

.socialNetworks {
  margin-top: 30px;

  @media (min-width: 280px) and (max-width: 540px) {
    margin-top: 10px;
  }

  svg {
    width: 40px;
    height: 40px;
    padding: 5px;
    &:hover {
      background-color: rgba(white, 0.25);
      border-radius: 5px;
    }

    
  }
  a:first-child {
    padding-right: 10px;
  }
}

.logoWrap {
  @media (min-width: 280px) and (max-width: 540px) {
    order: -1;
    margin-bottom: 15px;
  }

  img {
    @media (min-width: 671px) and (max-width: 900px) {
      width: 130px;
    }

    @media (min-width: 541px) and (max-width: 670px) {
      width: 100px;
    }

    @media (min-width: 280px) and (max-width: 540px) {
      width: 130px;
    }
  }
}

.scheduleWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  @media (min-width: 280px) and (max-width: 540px) {
    align-items: center;
    margin-top: 55px;
  }

  p {
    margin-bottom: 10px;
    display: block;
  }
}

.dayTimeWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;

  @media (min-width: 280px) and (max-width: 540px) {
    align-items: center;
    margin-top: 15px;
  }
}
